import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Contains in-progress loading requests
   */
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  constructor() { }

  /**
   * Sets the loadingSub property value based on the following:
   * - If loading is true, add the provided url to the loadingMap with a true value, set loadingSub value to true
   * - If loading is false, remove the loadingMap entry and only when the map is empty will we set loadingSub to false
   * This pattern ensures if there are multiple requests awaiting completion, we don't set loading to false before
   * other requests have completed. At the moment, this function is only called from the @link{HttpRequestInterceptor}
   * @param loading {boolean}
   * @param url {string}
   */
  setLoading(loading: boolean, url: string): void {
  const normalizedUrl = this.normalizeUrl(url);

    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }

    if (loading === true) {
      this.loadingMap.set(normalizedUrl, loading);
      this.loadingSub.next(true);

       // Establecer un tiempo máximo para eliminar automáticamente la URL
      setTimeout(() => {
        if (this.loadingMap.has(normalizedUrl)) {
          console.warn(`Timeout: Automatically clearing loading state for ${normalizedUrl}`);
          this.loadingMap.delete(normalizedUrl);
          if (this.loadingMap.size === 0) {
            this.loadingSub.next(false);
          }
        }
      }, 10000); // Tiempo máximo (10 segundos)

    } else if (loading === false && this.loadingMap.has(normalizedUrl)) {
      this.loadingMap.delete(normalizedUrl);
    }

    if (this.loadingMap.size === 0) {
      this.loadingSub.next(false);
    }
  }

  private normalizeUrl(url: string): string {
    const urlObj = new URL(url, window.location.origin); // Asegura que sea una URL válida
    return urlObj.origin + urlObj.pathname; // Ignora parámetros y fragmentos
  }
}
